import { set, ref, onValue } from "firebase/database";
import { db } from "../firebase";

export default function set_user_info(dispatch, user) {
  let create_info = {
    email: user?.email,
    name: user?.displayName,
    phone: user?.phoneNumber,
    metadata: user?.metadata,
    isAnonymous: user?.isAnonymous,
    emailVerified: user?.emailVerified,
    uid: user.uid,
    approved: "",
  };

  const obj = JSON.parse(user?.photoURL);
  create_info = { ...create_info, ...obj };

  dispatch({
    type: "set_user",
    payload: {
      ...create_info,
    },
  });

  onValue(ref(db), (snapshot) => {
    const data = snapshot.val();
    if (data[user?.uid]) {
      dispatch({
        type: "set_user",
        payload: {
          ...create_info,
          approved: data[user?.uid]["user"]["approved"] === "1",
        },
      });
    }
  });

  // Realtime Database
  onValue(ref(db), (snapshot) => {
    const data = snapshot.val();
    if (Object.keys(data || {}).indexOf(user?.uid) < 0) {
      set(ref(db, `/${user?.uid}`), {
        user: {
          email: user?.email,
          name: user?.displayName,
          uid: user.uid,
          approved: "0",
        },
      });
    }
    return;
  });
  // Realtime Database

  if (user?.email) {
    dispatch({ type: "set_authenticated" });
  }
}
