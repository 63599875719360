import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import Layout from "../components/Layout/Layout";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { onValue, ref } from "firebase/database";
import { db } from "../firebase";

const Home = () => {
  const { state } = useAuthContext();
  const [total_created, set_total_created] = useState(0);
  const [today_created, set_today_created] = useState(0);

  useEffect(() => {
    onValue(ref(db), (snapshot) => {
      const data = snapshot.val();
      const users = Object.keys(data || {});
      if (users.indexOf(state?.user?.uid) > -1) {
        const specific_user_data = data[state?.user.uid];
        set_total_created(Object.keys(specific_user_data).length - 1);

        let today = 0;
        Object.entries(specific_user_data).map(([key, val]) => {
          if (val?.createdAt === new Date().toDateString()) {
            today++;
          }
        });
        set_today_created(today);
      }
    });
  }, []);

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 text-2xl font-medium tracking-wide border-b">
          Dashboard
        </div>

        <div className="grid grid-cols-12 gap-5 pt-6 sm:p-6">
          <div className="col-span-12 md:col-span-6">
            <div className="rounded bg-pink-600 p-6 text-white flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6">
              <h2 className="text-3xl">
                <FontAwesomeIcon icon={faAddressCard} />
              </h2>
              <div className="flex flex-col items-start justify-start gap-1 w-full">
                <p className="text-xl tracking-wider font-semibold">
                  Today Take
                </p>
                <h4 className="text-3xl lg:text-4xl">
                  {state?.user?.approved ? today_created : 0}
                </h4>
                <div className="flex w-full gap-x-12 justify-between">
                  <small className="opacity-80">Cards</small>
                  <small>
                    <Link to="/history" className="text-blue-100 underline">
                      View History
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="rounded bg-green-600 p-6 text-white flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-6">
              <h2 className="text-3xl">
                <FontAwesomeIcon icon={faAddressCard} />
              </h2>
              <div className="flex flex-col items-start justify-start gap-1 w-full">
                <p className="text-xl tracking-wider font-semibold">
                  Total Take
                </p>
                <h4 className="text-3xl lg:text-4xl">
                  {state?.user?.approved ? total_created : 0}
                </h4>
                <div className="flex w-full gap-x-12 justify-between">
                  <small className="opacity-80">Cards</small>
                  <small>
                    <Link to="/history" className="text-blue-100 underline">
                      View History
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
