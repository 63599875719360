import { createContext, useReducer, useContext } from "react";

const State = {
  nid: {
    address: "",
    birth_place: "",
    blood_group: "",
    date_of_birth: "",
    father_name: "",
    mother_name: "",
    name_bn: "",
    name_en: "",
    nid_no: "",
    nid_pin: "",
    barcode_data: "",
    nid_photo: "",
    nid_signature: "",
  },
};

const reducer = (state = State, action) => {
  const { type, payload } = action;
  switch (type) {
    case "set_nid_info":
      return { ...state, nid: { ...payload } };

    default:
      return state;
  }
};

const NIDContext = createContext();

const NIDProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, State);

  return (
    <NIDContext.Provider value={{ state, dispatch }}>
      {children}
    </NIDContext.Provider>
  );
};

const useNIDContext = () => useContext(NIDContext);

export { NIDProvider, useNIDContext };
