import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faGauge,
  faAddressCard,
  faUser,
  faShieldHalved,
  faUserCheck,
  faChartLine,
  faDeleteLeft,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

import { NavLink } from "react-router-dom";

const Sidebar = () => {
  useEffect(() => {
    const all_ds_title = document.querySelectorAll(".ds_title");
    const all_ds_ul = document.querySelectorAll(".ds_ul");

    if (all_ds_title && all_ds_ul) {
      const heights = [];
      for (let i = 0; i < all_ds_ul.length; i++) {
        const ele = all_ds_ul[i];
        heights.push(ele.clientHeight);
        ele.style.height = ele.clientHeight + "px";
      }

      all_ds_title.forEach((title) => {
        let open = true;
        title.addEventListener("click", function () {
          const title_ref = this.dataset?.ref;

          const icon = this.children[1];
          if (open) {
            icon.style.transform = "rotate(-90deg)";
          } else {
            icon.style.transform = "rotate(0deg)";
          }

          all_ds_ul.forEach((item, i) => {
            const item_ref = item.dataset?.ref;
            if (title_ref === item_ref) {
              if (open) {
                item.style.height = "0px";
              } else {
                item.style.height = heights[i] + "px";
              }
              open = !open;
            }
          });
        });
      });
    }
  }, []);

  return (
    <section className="w-full lg:min-w-[350px] lg:w-[350px] p-5 rounded shadow-lg border">
      <div className="sidebar_item overflow-hidden">
        <button
          className="ds_title text-base font-normal w-full flex justify-between pb-1.5"
          data-ref="my-profile"
        >
          <span className="tracking-wider block">GENERAL</span>
          <span className="text-xs opacity-50 transition-all">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </button>
        <ul className="ds_ul transition-all" data-ref="my-profile">
          <li>
            <NavLink
              to="/"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faGauge} />
              </span>
              <span>Dashboard</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/create-nid"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faAddressCard} />
              </span>
              <span>Create NID Card</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/history"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faClockRotateLeft} />
              </span>
              <span>Your History</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <hr className="my-2" />
      <div className="sidebar_item overflow-hidden">
        <button
          className="ds_title text-base font-normal w-full flex items-center justify-between pb-1.5 pt-4"
          data-ref="setting"
        >
          <span className="tracking-wider block">SETTING</span>
          <span className="text-xs opacity-50 transition-all">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </button>
        <ul className="ds_ul transition-all" data-ref="setting">
          <li>
            <NavLink
              to="/profile"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <span>Profile</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink
              to="/security"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faShieldHalved} />
              </span>
              <span>Security</span>
            </NavLink>
          </li> */}

          <li>
            <NavLink
              to="/verification"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faUserCheck} />
              </span>
              <span>Verification</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <hr className="my-2" />
      <div className="sidebar_item overflow-hidden">
        <button
          className="ds_title text-base font-normal w-full flex justify-between pb-1.5 pt-4"
          data-ref="account"
        >
          <span className="tracking-wider block">ACCOUNT</span>
          <span className="text-xs opacity-50 transition-all">
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </button>
        <ul className="ds_ul transition-all" data-ref="account">
          <li>
            <NavLink
              to="/activities"
              className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase"
            >
              <span className="text-xs">
                <FontAwesomeIcon icon={faChartLine} />
              </span>
              <span>Activity Log</span>
            </NavLink>
          </li>
          <li>
            <button className="text-sm flex items-center justify-start w-full gap-2 text-[#2a3744] hover:bg-[#2a3744] hover:text-white rounded-sm p-2 uppercase">
              <span className="text-xs">
                <FontAwesomeIcon icon={faDeleteLeft} />
              </span>
              <span>Delete Account</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Sidebar;
