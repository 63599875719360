import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { ref, onValue, update } from "firebase/database";
import { db } from "../firebase";
import { useAuthContext } from "../context/AuthContext";

const NotActiveStatus = () => {
  const { state } = useAuthContext();
  const [is_approved, set_is_approved] = useState(false);
  const [is_requested, set_is_requested] = useState(false);

  function approve_request() {
    const uid = state?.user?.uid;

    if (uid) {
      update(ref(db, `/${uid}`), {
        user: {
          email: state?.user?.email,
          uid: state?.user.uid,
          approved: "request",
        },
      })
        .then(() => console.log("approved request"))
        .catch((error) =>
          console.log("approved request error: ", error?.message)
        )
        .finally(() => {
          console.log("finished");
        });
    }
  }

  useEffect(() => {
    onValue(ref(db), (snapshot) => {
      const data = snapshot.val();
      if (Object.keys(data || {}).indexOf(state?.user?.uid) > -1) {
        set_is_approved(data[state?.user?.uid]["user"]["approved"] === "1");
        set_is_requested(
          data[state?.user?.uid]["user"]["approved"] === "request"
        );
      }

      return;
    });
  }, [state]);

  if (!state?.authenticated || is_approved) {
    return <></>;
  }

  return (
    <div className="p-2 text-center bg-red-200 text-red-500 gap-x-2 flex items-center justify-center">
      {is_requested ? (
        <small> You have sent approved request! </small>
      ) : (
        <small className="lex flex-wrap justify-center items-center">
          <span>
            Your account is not active, Contact the administrator to activate
            your! account!
          </span>
          <button
            onClick={approve_request}
            className="px-3 py-1.5 mx-1 focus:ring-1 ring-offset-1 ring-red-500 rounded shadow bg-red-500 text-white w-fit cursor-pointer"
          >
            send approve request!
          </button>
        </small>
      )}

      <button className="group relative">
        <FontAwesomeIcon icon={faCircleInfo} />

        <span className="w-40 p-3 text-left hidden group-hover:block absolute top-[105%] right-0 bg-red-400 text-red-200 rounded shadow z-50">
          {is_requested ? (
            <small> Please wait, until approved your account! </small>
          ) : (
            <small>
              If you want to create a any document, you must activate your
              account!
            </small>
          )}
        </span>
      </button>
    </div>
  );
};

export default NotActiveStatus;
