import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import PrintAreaToPdf from "./PrintAreaToPdf";

const PrintPdfWrapper = () => {
  const { state } = useAuthContext();

  if (!state?.user?.approved) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{ minHeight: "calc(100vh - 75px)" }}
      className="flex items-center justify-center"
    >
      <PrintAreaToPdf />
    </div>
  );
};

export default PrintPdfWrapper;
