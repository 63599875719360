import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import { db } from "../firebase";
import { ref, onValue } from "firebase/database";
import { useAuthContext } from "../context/AuthContext";
import NIDCard from "../components/NIDCard";
import Button from "../components/Common/Button";
import { useNIDContext } from "../context/NIDContext";
import { Navigate } from "react-router-dom";

const History = () => {
  const { state } = useAuthContext();
  const [data, set_data] = useState([]);
  const [go_preview, set_go_preview] = useState(false);

  useEffect(() => {
    onValue(ref(db), (snapshot) => {
      const data = snapshot.val();

      const users = Object.keys(data || {});

      if (users.indexOf(state?.user?.uid) > -1) {
        const specific_user_data = [];
        Object.entries(data[state?.user.uid]).filter(([key, val]) => {
          if (key !== "user") {
            specific_user_data.push(val);
          }
        });

        const sorted = specific_user_data.sort(
          (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
        );

        set_data(sorted);
      }
    });
  }, []);

  if (go_preview) {
    return <Navigate to="/preview-nid" />;
  }

  return (
    <Layout>
      <div className="w-full overflow-auto bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 text-2xl font-medium tracking-wide border-b">
          Your History
        </div>

        <div className="flex flex-col space-y-12 py-12 px-6">
          {state?.user?.approved ? (
            data?.map((data, i) => (
              <SignleCard key={i} data={data} set_go_preview={set_go_preview} />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default History;

function SignleCard({ data, set_go_preview }) {
  const { dispatch } = useNIDContext();
  const [wait, set_wait] = useState(false);

  function printHandler() {
    set_wait(true);
    dispatch({
      type: "set_nid_info",
      payload: data,
    });
    setTimeout(() => {
      set_go_preview(true);
      set_wait(false);
    }, 1000);
  }

  return (
    <div className="bg-gray-50 p-4 w-fit h-fit space-y-4 shadow-xl border rounded">
      <NIDCard info={data} />
      <div className="w-fit lg:ml-auto">
        <Button loading={wait} onClick={printHandler}>
          <small>{wait ? "Please wait..." : "Print this document"}</small>
        </Button>
      </div>
    </div>
  );
}
