import * as React from "react";
import NIDCard from "../../components/NIDCard";
import { useNIDContext } from "../../context/NIDContext";

const Card = () =>  {
  const { state } = useNIDContext();
 return  <NIDCard info={state.nid} />
}

export default class PrintArea extends React.Component {
  render() {
    return <Card />;
  }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return <PrintArea ref={ref} text={props.text} />;
});
