import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { useAuthContext } from "../context/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../firebase";
import TostMessage from "../components/TostMessage";

const Verification = () => {
  const { state } = useAuthContext();

  const [text, set_text] = useState("Send Verification Link!");

  function send_email_handler() {
    set_text("Sending...");
    sendEmailVerification(auth.currentUser).then(() => {
      TostMessage(`Sent to ${state?.user?.email}`, "info", 5000);
      TostMessage("Check your spam folder!", "info", 5000);
      set_text("Email sent!");
    });
  }

  return (
    <Layout>
      <div className="w-full bg-white rounded-sm shadow-sm">
        <div className="px-5 py-4 text-blue-600 text-2xl font-medium tracking-wide border-b">
          Verification Status
        </div>

        <div className="grid grid-cols-12 gap-6 p-5">
          <div className="col-span-12">
            {state?.user?.emailVerified ? (
              <div className="py-3 text-lg tracking-wide text-green-600 flex items-center justify-start gap-x-2">
                <span>Your Account is Verified</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : (
              <>
                <div className="py-3 text-lg tracking-wide text-red-500 flex items-center justify-start gap-x-2">
                  <span>Not Verified</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <button
                    onClick={send_email_handler}
                    className="text-sm ml-3 font-medium hover:underline text-blue-400"
                  >
                    {text}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Verification;
